import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Loading = () => {
    return (
        <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <Spin
                indicator={
                    <LoadingOutlined
                        style={{
                            fontSize: 30,
                        }}
                        spin
                    />
                }
            />
        </div>
    );
};

export default Loading;

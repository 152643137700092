/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { uuid } from 'short-uuid';
import { twMerge } from 'tailwind-merge';
import axiosClient from '../../../api/axiosClient';
import FormInputSend from '../../common/component-embed/layout-mesenger/InputSend';
import FlowupEmbed from '../../common/component-embed/layout-mesenger/flowupembed';
import ResponseMesenger from '../../common/component-embed/layout-mesenger/responseMesenger';
import SuggestQuesiton from '../../common/component-embed/layout-mesenger/suggest';
import logoEmbed from '../../../assets/images/logoembed.svg';
import { createSSE, errorCommonStreaming } from '../../common/responseStreaming';
import Loading from '../../base/loading';
import { fadeOutVariants } from '../../../utils/animation';
import {
    convertDataHistorychat,
    filterAndRemoveDuplicates,
    filterAndRemoveDuplicatesList,
    filterDuplicateQuestions,
} from '../../../utils/sorting';
import EmbedLoginEmail from '../../common/loginembed';
import useDeboundced from '../../../hooks/useDeboudced';
import { decodeToken } from 'react-jwt';
import LoginPersonalization from '../../common/loginPersonalization';
import StylePersonzalion from '../../common/loginPersonalization/stylePersonalization';
import FirsMesenger from '../../common/component-embed/layout-mesenger/firstMesenger';
import { getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from '../../../utils/localStore';
import { useRecoilState } from 'recoil';
import { promptDebugState } from '../../../atom';

const BotDemoAndDebugMindmaid = React.memo(({ bot_id, dataBot, loading, isDebug = true }) => {
    const [jwtTokens, setTwtTokens] = useState(null);
    const [infoUser, setUserInfo] = useState(null);
    const [iFormProfile, setIFormProfile] = useState({ login: false, active: false, isUser: true });
    const [imageSend, setImageSend] = useState(null);
    const [suggest, setSuggest] = useState([]);
    const [dataHistory, setDataHistory] = useState([]);
    const [answer, setAnswer] = useState('');
    const [followup, setFollowup] = useState([]);
    const [setting, setSetting] = useState(null);
    const [question, setQuestion] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [checkTrainscript, setCheckTrainscrip] = useState(false);
    const [helloMessage, setHelloMessage] = useState(null);
    const [isEmptyDataHistory, setIsEmptyDataHistory] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [highlightedItem, setHighlightedItem] = useState(-1);
    const [errrImage, setErrorImage] = useState('');
    let defaultTextError = 'Đã cố lỗi xảy ra. Xin vui lòng thử lại sau 😣';
    const inputRef = useRef(null);
    const messagesEndRef = useRef(null);
    const [iform, setIForm] = useState(false);
    // const delay = 1;
    const { transcript, resetTranscript, listening } = useSpeechRecognition();
    const [, setShowPrompt] = useRecoilState(promptDebugState);
    const urldebug = process.env.REACT_APP_API_V3_URL;
    const url = process.env.REACT_APP_API_V3_URL;
    const baseURL_MINDMAID = process.env.REACT_APP_API_V3_URL;
    const [conversationId, setConversationId] = useState(null);

    try {
        const userData = localStorage.getItem(`login-debug-${bot_id}`);
        if (userData && !infoUser) {
            setUserInfo(decodeToken(userData));
        }
    } catch (error) {
        setUserInfo(null);
    }

    const [checkgetToken, setCheckgetToken] = useState(false);

    try {
        // const jwtTokenLocal = getFromLocalStorage('embeding_app', `token_bot_${bot_id}`);
        const jwtTokenLocal = localStorage.getItem(`jwt-debug-${bot_id}`);
        if (!jwtTokens && jwtTokenLocal) {
            setTwtTokens(jwtTokenLocal);
        }
    } catch (error) {
        setTwtTokens(null);
    }

    const saveConversationIdLocal = (conversation_id) => {
        saveToLocalStorage('embeding_app', `bot_${bot_id}`, conversation_id);
    };

    try {
        const id = getFromLocalStorage('embeding_app', `bot_${bot_id}`);
        if (id && !conversationId) {
            setConversationId(id);
        }
    } catch (error) {
        setConversationId(null);
    }

    const listenContinuously = () => {
        setCheckTrainscrip(true);
        resetTranscript();
        SpeechRecognition.startListening({
            continuous: true,
            language: 'vi-VN',
        });
    };

    useEffect(() => {
        if (!jwtTokens) {
            handlePassport();
        }
        if (jwtTokens) {
            if (dataBot?.id && !checkgetToken) {
                getIdChat(jwtTokens);
            }
        }
    }, [jwtTokens, dataBot?.id, checkgetToken]);

    const getFirstMessage = async (hello) => {
        if (!hello || dataHistory?.length > 0) return;
        // setSetting({
        //     answer: hello,
        // });
        let currentIndex = 0;
        const intervalId = setInterval(() => {
            const currentText = hello?.substring(0, currentIndex + 1);
            setSetting({
                answer: currentText,
            });
            setAnswer(currentText);
            currentIndex++;
            if (currentIndex === hello?.length || dataHistory?.length > 0) {
                clearInterval(intervalId);
                setSetting({
                    answer: hello,
                });
            }
        }, Math.random() * 0 + 10);
    };

    const handleStateNewChat = () => {
        setDataHistory((prev) => [
            ...prev,
            {
                question: '',
                answer: 'Bạn đã xác thực thành công, Hãy tiếp tục đặt câu hỏi',
            },
        ]);
        getStartDisplay();
    };
    const handleStateNewChatAnonymous = () => {
        setDataHistory((prev) => [
            ...prev,
            {
                question: '',
                answer: 'Bạn đã chọn chat Ẩn danh. Có thể tiếp tục đặt câu hỏi',
            },
        ]);
        setIsTyping(false);
    };

    const handleUpdateHistory = (property, value) => {
        setDataHistory((prev) => {
            if (prev.length === 0) {
                return [{ [property]: value }];
            }
            if (property === 'question') {
                const newObject = {
                    id: uuid(),
                    question: value,
                    answer: '',
                    sendImage: null,
                    source: [],
                    images: [],
                    videos: [],
                    prompt: '',
                    data_table: '',
                };
                return [...prev, newObject];
            }
            return prev.map((data, index) => {
                if (index === prev.length - 1) {
                    return { ...data, [property]: value };
                }
                return data;
            });
        });
    };

    const updateSendImage = async (sendImage) => {
        const newImage = URL.createObjectURL(sendImage);
        setDataHistory((prev) => {
            const data = prev;
            if (data?.length > 0) data[data?.length - 1].sendImage = newImage;

            return data;
        });
    };

    const updateImages = async (images) => {
        try {
            const filteredImages = await filterAndRemoveDuplicates(images);

            setDataHistory((prev) => {
                const data = [...prev];
                if (data?.length > 0) {
                    data[data?.length - 1].images = filteredImages;
                }
                return data;
            });
        } catch (error) {
            console.error('Có lỗi xảy ra trong quá trình cập nhật ảnh:', error);
        }
    };

    const handleUpdateDataTable = (data_table) => {
        setDataHistory((prev) => {
            const data = prev;
            if (data?.length > 0) data[data?.length - 1].data_table = data_table;
            return data;
        });
    };
    const updateVideo = (video) => {
        const filteredVideo = filterAndRemoveDuplicatesList(video);
        setDataHistory((prev) => {
            const data = prev;
            if (data?.length > 0) data[data?.length - 1].videos = filteredVideo;

            return data;
        });
    };
    const getPrompt = async (question) => {
        setShowPrompt((prev) => ({ ...prev, loading: true, question: question }));
        const payload = new FormData();
        payload.append('question', question ? question : ' ');
        payload.append('bot_id', bot_id);
        payload.append('get_prompt', true);
        payload.append('conversation_id', conversationId);

        try {
            let jwt ;
            if (jwtTokens?.includes('"')) {
                jwt = jwtTokens?.slice(1, -1);
            } else {
                jwt = jwtTokens;
            }
            const res = await axios.post(`${urldebug}/v1/answers`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${jwt}`,
                },
            });
            if (res.status === 200 || res.status === 201) {
                setShowPrompt((prev) => ({ ...prev, loading: false, prompt: res.data.prompt }));
                handleUpdateHistory('prompt', res.data.prompt);
            } else {
                setShowPrompt((prev) => ({ ...prev, loading: false, prompt: '' }));
            }
        } catch (error) {
            setShowPrompt((prev) => ({ ...prev, loading: false, prompt: '' }));
        }
    };

    const updateSoucres = (source) => {
        const filteredSoucre = filterAndRemoveDuplicatesList(source);
        setDataHistory((prev) => {
            const data = prev;
            if (data?.length > 0) data[data?.length - 1].source = filteredSoucre;

            return data;
        });
    };

    // --- streming response call api
    const createStreaming = async (question) => {
        // if (!question) return;
        setIsTyping(true);
        if (imageSend) updateSendImage(imageSend);
        const requestsQuestion = {
            question: question ? question : ' ',
            bot_id: bot_id,
            ...(imageSend && { image: imageSend }),
            ...(conversationId && { conversation_id: conversationId }),
        };
        if (isDebug) {
            getPrompt(question);
        }
        let source = createSSE(requestsQuestion, jwtTokens);
        let isNochat = false;
        let isGetllow = null;

        try {
            const handleMessage = async (e) => {
                try {
                    const payload = JSON.parse(e?.data);
                    if (e.data !== '[DONE]' && e.data !== 'ping' && payload) {
                        const { conversation_id, answer, image, video, source, data_table } = payload;
                        if (conversation_id) {
                            saveConversationIdLocal(conversation_id);
                            setConversationId(conversation_id);
                            isGetllow = conversation_id;
                        }
                        if (answer && answer !== '\n') {
                            handleUpdateHistory('answer', answer);
                            setAnswer(answer);
                        }
                        if (data_table) handleUpdateDataTable(data_table);
                        if (image && image.length !== 0) updateImages(image);
                        if (video && video.length !== 0) updateVideo(video);
                        if (source && source.length !== 0) updateSoucres(source);
                    } else {
                        source.close();
                    }
                } catch (error) {
                    source.addEventListener('message', handleMessage);
                }
            };

            source.addEventListener('message', handleMessage);

            source.addEventListener('readystatechange', (e) => {
                if (e.readyState >= 2) {
                    getFlowUp(isGetllow || conversationId);

                    !isNochat && setIsTyping(false);
                    setAnswer('done');
                }
            });

            source.addEventListener('error', (e) => {
                let err = null;
                try {
                    err = JSON.parse(e.data);
                } catch (error) {
                    err = null;
                }

                if (err?.msg?.includes('Invalid token')) {
                    saveConversationIdLocal('');
                    handlePassport();
                    handleUpdateHistory('answer', 'Đã có lỗi xảy ra. Vui lòng tải lại trang để tiếp tục sử dụng');
                    setIsTyping(false);
                    return;
                }

                if (err?.msg?.includes('Bạn không có quyền với hội thoại')) {
                    saveConversationIdLocal('');
                    setIsTyping(false);
                    setQuestionBackUp(question);
                    return;
                }

                if (err?.code === 4031 || err?.msg?.includes('Rất tiếc, bạn đã sử dụng hết số lượt hỏi ẩn danh')) {
                    setIForm(true);
                    isNochat = true;
                    const text = 'Rất tiếc, bạn đã sử dụng hết số lượt hỏi ẩn danh, hãy xác thực email để tiếp tục';
                    handleUpdateHistory('answer', text || err?.msg);
                    setIsTyping(true);
                    return;
                }

                const messageError = errorCommonStreaming(e, dataBot?.no_money_notice);
                handleUpdateHistory('answer', messageError);
                setAnswer(messageError);
            });
            setImageSend(null);
            source.stream();
        } catch (error) {
            handleUpdateHistory('answer', defaultTextError);
            setAnswer(defaultTextError);
        }
    };

    //auto scroll
    const scrollToBottom = () => {
        if (messagesEndRef?.current)
            messagesEndRef.current.scrollTo({ top: messagesEndRef.current.scrollHeight, behavior: 'smooth' });
    };

    const onSubmit = async (e) => {
        if (e) e.preventDefault();

        if (!question && !imageSend) {
            inputRef.current?.focus();
            return;
        }
        if (isTyping) return inputRef.current?.focus();

        setFollowup([]);

        setCheckTrainscrip(false);
        SpeechRecognition.stopListening();
        resetTranscript();
        handleUpdateHistory('question', question);
        // scrollToBottom();
        await createStreaming(question);
        scrollToBottom();
        clearInput();
        setQuestion('');
    };

    const clearInput = () => {
        if (inputRef.current !== null) {
            inputRef.current.value = '';
        }
    };

    const followupQuestion = (question) => {
        if (!question) return inputRef.current?.focus();
        if (isTyping) return;

        setImageSend(null);
        setCheckTrainscrip(false);
        SpeechRecognition.stopListening();
        resetTranscript();
        clearInput();
        setFollowup([]);
        handleUpdateHistory('question', question);
        inputRef.current.value = '';
        createStreaming(question);
    };

    const getFlowUp = async (id) => {
        try {
            const res = await axios.get(`${baseURL_MINDMAID}/api/find-followup/${conversationId || id}`);
            if (res.status === 200 || res.status === 201) {
                const newData = res.data?.map((i) => ({ question: i }));
                return setFollowup(newData);
            } else {
                return setFollowup([]);
            }
        } catch (error) {
            return setFollowup([]);
        }
    };
    const getHellWord = async () => {
        const url = `greeting-message?bot_id=${bot_id}&data_id=${infoUser?.data_id}`;
        let result = 'Xin chào bạn! ';
        try {
            const res = await axiosClient.get(url);
            if (res.status === 200 || res.status === 201) {
                result = res.data?.greeting_message;
                setHelloMessage(result);
            }
            return result;
        } catch (error) {
            return dataBot?.isMessage;
        }
    };

    const handleClearInput = () => {
        if (question !== '') setQuestion('');
        if (inputRef?.current?.value) {
            inputRef.current.value = '';
            inputRef.current.focus();
        }
    };

    const handleDeleteHistory = async () => {
        if (isLoadingDelete || dataHistory?.length < 1) return;
        setIsLoadingDelete(true);
        try {
            const res = await axiosClient.delete(`conversations/delete?conversation_id=${conversationId}`);

            if (res.status <= 205) {
                removeFromLocalStorage('embeding_app', `bot_${bot_id}`);
                removeFromLocalStorage('embeding_app', `token_bot_${bot_id}`);
                setConversationId(null);
                setIsLoadingDelete(false);
                setIsEmptyDataHistory(true);
                handleSetHello();
                setTimeout(() => {
                    setDataHistory([]);
                    setIsEmptyDataHistory(false);
                    handlePassport()
                }, 200);
            } else {
                setIsLoadingDelete(false);
                setIsEmptyDataHistory(false);
            }
        } catch (error) {
            setIsLoadingDelete(false);
        }
    };

    const handleSetHello = () => {
        if (helloMessage) {
            setSetting({
                answer: helloMessage,
            });
        } else {
            setSetting({
                answer: '',
            });
        }
    };

    const getAutoComplete = async (query) => {
        if (query === '') return;
        try {
            const res = await axiosClient.get(`${url}/api/question/autocomplete?bot_id=${bot_id}&keyword=${query}`);
            if (res.status === 200 || res.status === 201) {
                const arr = res.data.data.map((i) => i.split(' /n '));
                const obj = arr.map((data) => data.reduce((a, v) => ({ ...a, id: a.question, question: v }), {}));
                setSuggest(obj.splice(0, 3));
            } else {
                setSuggest([]);
            }
        } catch (err) {
            setSuggest([]);
        }
    };
    const [questionBackup, setQuestionBackUp] = useState('');

    useEffect(() => {
        if (questionBackup) {
            createStreaming(questionBackup);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionBackup]);

    const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);

    const handleScroll = () => {
        const messagesEnd = messagesEndRef.current;
        if (messagesEnd) {
            const scrollTop = messagesEnd.scrollTop;
            const clientHeight = messagesEnd.clientHeight;
            const scrollHeight = messagesEnd.scrollHeight;
            const isAtBottom = Math.abs(scrollTop + clientHeight - scrollHeight) <= 1;
            setIsScrolledToBottom(isAtBottom);
            // setAutoScroll(isAtBottom);
        }
    };

    useEffect(() => {
        const messagesEnd = messagesEndRef.current;
        if (messagesEnd) {
            messagesEnd.addEventListener('scroll', handleScroll);
            return () => {
                messagesEnd.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    useEffect(() => {
        const messagesEnd = messagesEndRef.current;
        if (messagesEnd) {
            const scrollHeight = messagesEnd.scrollHeight;

            if (isScrolledToBottom && (isTyping || answer === 'scroll')) {
                messagesEnd.style.scrollBehavior = 'auto';
                messagesEnd.scrollTo({
                    top: scrollHeight,
                    behavior: 'auto',
                });
            }

            setAnswer('');
        }
    }, [answer, dataHistory, followup, isTyping, isScrolledToBottom]);

    useEffect(() => {
        if (transcript !== '' && checkTrainscript) {
            setQuestion(transcript);
            inputRef.current.value = transcript;
        }
        // eslint-disable-next-line
    }, [transcript]);

    const useDebounced = useDeboundced(question, 1200);

    useEffect(() => {
        if (useDebounced?.length >= 2 && !isTyping) {
            getAutoComplete(useDebounced);
        }
    }, [useDebounced]);

    const getStartDisplay = async () => {
        try {
            const res = await axiosClient.get(`starters-questions/?bot_id=${bot_id}`);
            if (res.status === 200 || res.status === 201) {
                const welcomQuesiton = filterDuplicateQuestions(res.data);
                setFollowup(welcomQuesiton);
            } else {
                setFollowup([]);
            }
        } catch (error) {
            setFollowup([]);
        }
    };

    const handleAnswerPersonzaliation = async (data = []) => {
        if (!infoUser?.isLogin && dataBot?.is_personal_mode) {
            setIsTyping(true);
            const helloMessage = await getHellWord();
            await getFirstMessage(helloMessage);
            await delayedActions(data);
            return;
        }
        if (infoUser?.isLogin && dataBot?.is_personal_mode) {
            const helloMessage = await getHellWord();
            await getFirstMessage(helloMessage);
            await delayedResponse(data);
        } else {
            const helloMessage = await getHellWord();
            await getFirstMessage(helloMessage);
            if (data?.length > 0) {
                await delayedResponseNewChat(data);
            }
            getStartDisplay();
            setIsTyping(false);
        }
    };

    const delayedActions = async (data) => {
        await new Promise((resolve) => setTimeout(resolve, 1800));

        const personalMode = dataBot?.personal_mode || [];
        const includesPhone = personalMode.includes(0) || personalMode.includes('0');
        const includesEmail = personalMode.includes(1) || personalMode.includes('1');

        if (!includesPhone && !includesEmail) {
            setDataHistory([...data]);
        } else {
            setDataHistory([
                ...data,
                {
                    question: '',
                    answer: dataBot?.title_require_form_personal,
                },
            ]);
        }

        await new Promise((resolve) => setTimeout(resolve, 300));

        if (!includesPhone && !includesEmail) {
            setIFormProfile({ login: false, active: false, isUser: true });
            setIsTyping(false);
        } else if (includesPhone) {
            setIFormProfile({ login: true, active: false, isUser: true });
            setIsTyping(true);
            setCheckgetToken(true);
        } else {
            setIFormProfile({ login: false, active: true, isUser: true, isComfirm: true });
            setIsTyping(true);
        }
    };

    const delayedResponseNewChat = async (data) => {
        setDataHistory([...data]);
        setIsTyping(false);
    };
    const delayedResponse = async (data) => {
        await new Promise((resolve) => setTimeout(resolve, 1800));
        setDataHistory([
            ...data,
            {
                question: '',
                answer: 'Bạn đã xác thực thành công, Hãy tiếp tục đặt câu hỏi',
            },
        ]);
        getStartDisplay();
        setIsTyping(false);
    };

    const getHistoryChat = async (idHistory) => {
        try {
            const res = await axiosClient.get(`/conversations/history?conversation_id=${idHistory}`);
            if (res.status <= 205) {
                if (res?.data?.messages?.length <= 0) {
                    await handleAnswerPersonzaliation();
                    return;
                }
                const data = convertDataHistorychat(res.data?.messages);
                if (data) {
                    await handleAnswerPersonzaliation(data);
                } else {
                    await handleAnswerPersonzaliation(data);
                }
            }
        } catch (error) {
            await handleAnswerPersonzaliation();
        }
    };

    const getIdChat = async (jwtToken) => {
        const jwt = jwtToken.slice(1, -1);
        setIsTyping(true);
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_V3_URL}/api/list-conversation`, {
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            });
            if (res.status <= 205) {
                if (res.data?.length > 0) {
                    getHistoryChat(res?.data[0]?.id);
                } else {
                    await handleAnswerPersonzaliation();
                }
            } else {
                await handleAnswerPersonzaliation();
            }
        } catch (error) {
            await handleAnswerPersonzaliation();
        }
    };

    const handleSetHighlightedItem = ({ index, event }) => {
        const setValues = (index) => {
            setHighlightedItem(index);
        };
        if (index !== undefined) {
            setHighlightedItem(index);
            return;
        }
        if (!event) {
            return;
        }
        const textarea = inputRef.current;
        switch (event.key) {
            case 'Enter':
                if (event.shiftKey) {
                    event.preventDefault();
                    const cursorPos = textarea.selectionStart;
                    const textBeforeCursor = textarea.value.substring(0, cursorPos);
                    const textAfterCursor = textarea.value.substring(cursorPos);
                    const newText = `${textBeforeCursor}\n${textAfterCursor}`;
                    textarea.value = newText;
                    textarea.style.height = '24px';
                    // Ensure that the scrollHeight is not greater than 200px
                    if (textarea.scrollHeight <= 150 && textarea.scrollHeight > 40) {
                        textarea.style.height = 'auto';
                        textarea.style.height = `${textarea.scrollHeight}px`;
                    } else if (textarea.scrollHeight > 150) {
                        textarea.style.height = '150px';
                    }
                } else if (suggest?.length > 0 && suggest[highlightedItem]) {
                    event.preventDefault();
                    followupQuestion(suggest[highlightedItem].question, suggest[highlightedItem].id, '', '');
                    // setQuestion('');
                } else {
                    event.preventDefault();
                    onSubmit();
                    textarea.style.height = '24px';
                    // setQuestion('');
                }
                setHighlightedItem(-1);
                setSuggest([]);
                break;

            case 'ArrowUp':
                event.preventDefault();
                const upIndex = highlightedItem > -1 ? highlightedItem - 1 : suggest?.length - 1;
                setValues(upIndex);
                if (inputRef.current) {
                    inputRef.current.value =
                        upIndex > -1 && suggest[upIndex].question ? suggest[upIndex].question : question;
                }
                break;

            case 'ArrowDown':
                event.preventDefault();
                const downIndex = highlightedItem < suggest?.length - 1 ? highlightedItem + 1 : -1;
                setValues(downIndex);
                if (inputRef.current) {
                    inputRef.current.value =
                        downIndex > -1 && suggest[downIndex].question ? suggest[downIndex].question : question;
                }
                break;

            case 'Escape':
                event.preventDefault();
                if (inputRef.current) {
                    inputRef.current.value = question;
                }
                setSuggest([]);
                break;

            default:
                break;
        }
    };

    const updateLastAnswer = (Text) => {
        const loginText = 'Bạn đã đang nhập thành công. Có thể tiếp tục sử dụng dịch vụ';
        getFirstMessage(Text || loginText);
        saveConversationIdLocal('');
        setDataHistory([]);
    };

    const handlePassport = async () => {
        try {
            const res = await axiosClient.get('passport', {
                headers: {
                    'X-bot-Code': `${bot_id}`,
                },
            });
            if (res.status <= 205) {
                const parsedBotIds = res.data.access_token;
                localStorage.setItem(`jwt-debug-${bot_id}`, JSON.stringify(parsedBotIds));
                // saveToLocalStorage('embeding_app', `token_bot_${bot_id}`, JSON.stringify(parsedBotIds));
                setTwtTokens(parsedBotIds);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const files = e.dataTransfer.files;
            setImageSend(files[0]);
        }
    };
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handlePaste = (e) => {
        const items = (e.clipboardData || e.originalEvent.clipboardData).items;
        for (let index in items) {
            const item = items[index];
            if (item.kind === 'file') {
                const blob = item.getAsFile();
                setImageSend(blob);
            }
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="w-full h-full " onDrop={handleDrop} onDragOver={handleDragOver} onPaste={handlePaste}>
            <div className="w-full h-full mx-auto ">
                <div className="flex flex-col flex-1 h-full py-0 lg:my-0">
                    <div className="static flex flex-col h-full bg-white" id="embed-bot">
                        {/* body  */}
                        <div
                            className={twMerge('overflow-y-auto thin-scroll', followup?.length === 0 && 'pb-10')}
                            ref={messagesEndRef}
                        >
                            <div className="mx-2 my-3 mb-4">
                                {/* body helo FirsMesenger */}
                                <FirsMesenger setting={setting} dataHistory={dataHistory} dataBot={dataBot} />

                                <motion.div
                                    className="message"
                                    initial="visible"
                                    animate={isEmptyDataHistory ? 'visible' : 'hidden'}
                                    variants={fadeOutVariants}
                                >
                                    <ResponseMesenger dataHistory={dataHistory} dataBot={dataBot} />
                                    {iform && (
                                        <EmbedLoginEmail
                                            setIsTyping={setIsTyping}
                                            scrollToBottom={scrollToBottom}
                                            setIForm={setIForm}
                                            botId={dataBot?.id}
                                            updateLastAnswer={updateLastAnswer}
                                            logo={dataBot?.preview_logo ? dataBot?.preview_logo : logoEmbed}
                                        />
                                    )}
                                    {iFormProfile?.login && (
                                        <div className="ml-12 mr-2">
                                            <LoginPersonalization
                                                botId={dataBot?.id}
                                                dataBot={dataBot}
                                                typeStore="debug"
                                                getHellWord={handleStateNewChatAnonymous}
                                                setIFormProfile={setIFormProfile}
                                                iFormProfile={iFormProfile}
                                                setTwtTokens={setTwtTokens}
                                            />
                                        </div>
                                    )}
                                    {iFormProfile?.active && (
                                        <div className="ml-12 mr-2">
                                            <StylePersonzalion
                                                botId={bot_id}
                                                dataBot={dataBot}
                                                typeStore="debug"
                                                iFormProfile={iFormProfile}
                                                setUserInfo={setUserInfo}
                                                setIsTyping={setIsTyping}
                                                setIFormProfile={setIFormProfile}
                                                handleStateNewChat={handleStateNewChat}
                                            />
                                        </div>
                                    )}
                                </motion.div>
                                {/* body flowup */}
                                <FlowupEmbed
                                    followup={followup}
                                    isTyping={isTyping}
                                    dataBot={dataBot}
                                    followupQuestion={followupQuestion}
                                />
                            </div>
                        </div>

                        {/* body form */}
                        <div className=" w-full pl-3 py-3 mt-auto bg-white lg:pr-4 pr-4 border-t border-[#EBEBEB]">
                            {errrImage && <p className="text-xs font-light text-danger">{errrImage}</p>}
                            {imageSend ? (
                                <div className="flex justify-start ml-10">
                                    <img className="w-[50px] rounded" src={URL.createObjectURL(imageSend)} alt="send" />
                                    <IoCloseCircleOutline
                                        onClick={() => setImageSend(null)}
                                        size={20}
                                        className="text-red-500 cursor-pointer"
                                    />
                                </div>
                            ) : (
                                <SuggestQuesiton
                                    suggest={suggest}
                                    question={question}
                                    highlightedItem={highlightedItem}
                                    setSuggest={setSuggest}
                                    setHighlightedItem={setHighlightedItem}
                                    followupQuestion={followupQuestion}
                                    handleSetHighlightedItem={handleSetHighlightedItem}
                                />
                            )}

                            {/* form send */}
                            <FormInputSend
                                setErrorImage={setErrorImage}
                                imageSend={imageSend}
                                onSubmit={onSubmit}
                                setIsEmptyDataHistory={setIsEmptyDataHistory}
                                handleDeleteHistory={handleDeleteHistory}
                                handleClearInput={handleClearInput}
                                setFollowup={setFollowup}
                                setDataHistory={setDataHistory}
                                isTyping={isTyping}
                                setImageSend={setImageSend}
                                dataHistory={dataHistory}
                                dataBot={dataBot}
                                inputRef={inputRef}
                                question={question}
                                setQuestion={setQuestion}
                                listening={listening}
                                handleSetHighlightedItem={handleSetHighlightedItem}
                                SpeechRecognition={SpeechRecognition}
                                resetTranscript={resetTranscript}
                                setCheckTrainscrip={setCheckTrainscrip}
                                listenContinuously={listenContinuously}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default BotDemoAndDebugMindmaid;

import React from 'react';
import { Controller } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
const InputController = ({
    name,
    control,
    required,
    errors,
    defaultValues = '',
    maxValue = null,
    className,
    noArrow = false,
    ...props
}) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={{
                required: required,
            }}
            defaultValue={defaultValues}
            render={({ field }) => (
                <input
                    autoComplete="off"
                    className={twMerge(
                        'appearance-none block w-full rounded-lg pl-[14px] px-3 py-2 h-[36px] border border-gray-200 hover:border-gray-300 hover:shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary placeholder-gray-400 caret-primary-600 sm:text-sm pr-10 text-sm duration-400 focus:ring-1',
                        className,
                        noArrow && 'no-arrow',
                    )}
                    style={{
                        borderColor: errors?.[name] && 'errors',
                        boxShadow: errors?.[name] && 'rgb(229, 62, 62) 0px 0px 0px 1px',
                    }}
                    onKeyPress={(e) => {
                        if (props.type === 'number' && e.key === '-') {
                            e.preventDefault();
                        }
                        if (maxValue)
                            if (e.target.value?.length >= maxValue) {
                                e.preventDefault();
                            }
                    }}
                    {...props}
                    {...field}
                />
            )}
        />
    );
};

export default InputController;

import React, { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import katex from 'katex';
import 'katex/dist/katex.min.css';

const KaTeX = ({ math, inline }) => {
    const mathString = String(math);
    return React.createElement(inline ? 'span' : 'div', {
        dangerouslySetInnerHTML: {
            __html: katex.renderToString(mathString, { throwOnError: false }),
        },
    });
};

const MarkdownRender = ({ childrenRender, className = '' }) => {
    const renderedMarkdown = useMemo(
        () => (
            <ReactMarkdown
                children={childrenRender}
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeKatex]}
                components={{
                    math: ({ value }) => <KaTeX math={value} />,
                    inlineMath: ({ value }) => <KaTeX math={value} inline />,
                    a: ({ href, children }) => (
                        <a href={href} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                            {children}
                        </a>
                    ),
                }}
                className={className}
            />
        ),
        [childrenRender, className],
    );

    return renderedMarkdown;
};

export default MarkdownRender;

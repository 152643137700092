import { useState } from 'react';
import { Tooltip } from 'antd';
import { IoCopyOutline } from 'react-icons/io5';
import { TiTick } from 'react-icons/ti';

const CopyBtn = ({ value, className, isPlain }) => {
    const [isCopied, setIsCopied] = useState(false);
    const copyToClipBoard = async (copyMe) => {
        try {
            await navigator.clipboard.writeText(copyMe);
        } catch (err) {
            console.log('Sao chép thất bại');
        }
    };
    return (
        <div className={`${className}`}>
            <Tooltip className="z-10">
                <div
                    className={'box-border p-0.5 flex items-center justify-center rounded-md bg-white cursor-pointer'}
                    style={
                        !isPlain
                            ? {
                                  boxShadow:
                                      '0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)',
                              }
                            : {}
                    }
                    onClick={() => {
                        copyToClipBoard(value);
                        setIsCopied(true);
                    }}
                >
                    {isCopied ? <TiTick className="text-green-500" /> : <IoCopyOutline className="text-gray-500" />}
                </div>
            </Tooltip>
        </div>
    );
};

export default CopyBtn;

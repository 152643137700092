import ReactMarkdown from 'react-markdown';
import 'katex/dist/katex.min.css';
import RemarkMath from 'remark-math';
import RemarkBreaks from 'remark-breaks';
import RehypeKatex from 'rehype-katex';
import RemarkGfm from 'remark-gfm';
import classNames from 'classnames';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atelierHeathLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import CopyBtn from '../copy-btn';
const capitalizationLanguageNameMap = {
    sql: 'SQL',
    javascript: 'JavaScript',
    java: 'Java',
    typescript: 'TypeScript',
    vbscript: 'VBScript',
    css: 'CSS',
    html: 'HTML',
    xml: 'XML',
    php: 'PHP',
    python: 'Python',
    yaml: 'Yaml',
    mermaid: 'Mermaid',
    markdown: 'MarkDown',
    makefile: 'MakeFile',
};

const getCorrectCapitalizationLanguageName = (language) => {
    if (!language) return 'Plain';

    if (language in capitalizationLanguageNameMap) return capitalizationLanguageNameMap[language];

    return language.charAt(0).toUpperCase() + language.substring(1);
};

export function Markdown(props) {
    return (
        <div className={classNames(props.className, 'markdown-body')}>
            <ReactMarkdown
                remarkPlugins={[[RemarkMath, { singleDollarTextMath: false }], RemarkGfm, RemarkBreaks]}
                rehypePlugins={[RehypeKatex]}
                components={{
                    // code({ inline, className, children, ...props }) {
                    //     const match = /language-(\w+)/.exec(className || '');
                    //     const language = match?.[1];
                    //     const languageShowName = getCorrectCapitalizationLanguageName(language || '');

                    //     return !inline && match ? (
                    //         <div>
                    //             <div
                    //                 className="flex justify-between h-8 items-center p-1 pl-3 border-b"
                    //                 style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }}
                    //             >
                    //                 <div className="text-[13px] text-gray-500 font-normal">{languageShowName}</div>
                    //             </div>
                    //         </div>
                    //     ) : (
                    //         <code {...props} className={className}>
                    //             {children}
                    //         </code>
                    //     );
                    // },
                    code({ inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '');
                        const language = match?.[1];
                        const languageShowName = getCorrectCapitalizationLanguageName(language || '');
                        return !inline && match ? (
                            <div className='my-1'>
                                <div
                                    className="flex justify-between h-10 bg-slate-100 rounded-t items-center p-1 pl-3 border-b"
                                    style={{
                                        borderColor: 'rgba(0, 0, 0, 0.05)',
                                    }}
                                >
                                    <div className="text-[13px] text-gray-500 font-normal">{languageShowName}</div>
                                    <div style={{ display: 'flex' }}>
                                        <CopyBtn className="mr-1" value={String(children).replace(/\n$/, '')} isPlain />
                                    </div>
                                </div>

                                <SyntaxHighlighter
                                    {...props}
                                    style={atelierHeathLight}
                                    customStyle={{
                                        paddingLeft: 5,
                                        backgroundColor: '#fff',
                                        borderRadius:10,
                                        fontSize:13,
                                        marginTop:'-7px'
                                    }}
                                    language={match[1]}
                                    showLineNumbers
                                    PreTag="div"
                                >
                                    {String(children).replace(/\n$/, '')}
                                </SyntaxHighlighter>
                            </div>
                        ) : (
                            <code {...props} className={className}>
                                {children}
                            </code>
                        );
                    },
                    img({ src, alt, ...props }) {
                        return (
                            <img
                                src={src}
                                alt={alt}
                                width={250}
                                height={250}
                                className="max-w-full h-auto align-middle border-none rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out mt-2 mb-2"
                                {...props}
                            />
                        );
                    },

                    p: (paragraph) => {
                        const { node } = paragraph;

                        if (node.children[0].tagName === 'img') {
                            const image = node.children[0];

                            return (
                                <>
                                    <img
                                        src={image.properties.src}
                                        width={250}
                                        height={250}
                                        className="max-w-full h-auto align-middle border-none rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out mt-2 mb-2"
                                        alt={image.properties.alt}
                                    />
                                    <p>{paragraph.children.slice(1)}</p>
                                </>
                            );
                        }

                        return <p>{paragraph.children}</p>;
                    },
                    ul: ({ children }) => <ul className="list-disc list-inside">{children}</ul>,
                    ol: ({ children }) => <ol className="list-decimal list-inside">{children}</ol>,
                    a: ({ href, children }) => (
                        <a href={href} target="_blank" rel="noopener noreferrer" className="!text-blue-500">
                            {children}
                        </a>
                    ),
                    table: ({ children }) => <table className="table-auto border-collapse">{children}</table>,
                    th: ({ children }) => <th className="border px-4 py-2">{children}</th>,
                    td: ({ children }) => <td className="border px-4 py-2">{children}</td>,
                }}
                linkTarget="_blank"
            >
                {props.content}
            </ReactMarkdown>
        </div>
    );
}

import React from 'react';
import { Col, ColorPicker, Row, Divider, theme } from 'antd';
import { generate, green, presetPalettes, red, cyan } from '@ant-design/colors';

const genPresets = (presets = presetPalettes) =>
    Object.entries(presets).map(([label, colors]) => ({
        label,
        colors,
    }));

const ColorPickerCustom = ({ value, onChange }) => {
    const { token } = theme.useToken();
    const handleColorChange = (color) => {
        const originalInput = color.toHexString();
        onChange(originalInput); 
      };

    const presets = genPresets({
        primary: generate(token.colorPrimary),
        red,
        green,
        cyan,
    });

    const customPanelRender = (_, { components: { Picker, Presets } }) => (
        <Row justify="space-between" wrap={false}>
            <Col span={12}>
                <Presets />
            </Col>
            <Divider
                type="vertical"
                style={{
                    height: 'auto',
                }}
            />
            <Col flex="auto">
                <Picker />
            </Col>
        </Row>
    );
    return (
        <ColorPicker
            defaultValue={value}
            value={value}
            onChange={handleColorChange}
            allowClear
            styles={{
                popupOverlayInner: {
                    width: 500,
                },
            }}
            presets={presets}
            panelRender={customPanelRender}
        />
    );
};
export default ColorPickerCustom;
